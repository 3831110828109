const Shader = require('../Core/Shader')
const Uniform = require('../Core/Uniform')

module.exports = new Shader('FXAAEffect', 1, { resolution: new Uniform('resolution', [ 500.0, 500.0 ], 'float_vec2') }, [
    'attribute vec2 v_coord;',
    'uniform sampler2D fbo_texture;',
    'uniform vec2 resolution;',
    'varying vec2 f_texcoord;',
    'void main() {',
        'gl_Position = vec4(v_coord, 0.0, 1.0);',
        'f_texcoord = (v_coord + 1.0) / 2.0;',
    '}'
], /*
[
    '#define FXAA_REDUCE_MIN   (1.0/ 128.0)',
    '#define FXAA_REDUCE_MUL   (1.0 / 8.0)',
    '#define FXAA_SPAN_MAX     8.0',

    'vec4 applyFXAA(vec2 fragCoord, sampler2D tex, vec2 resolution)',
    '{',
        'fragCoord = fragCoord * resolution;',
        'vec2 inverseVP = vec2(1.0 / 500.0, 1.0 / 500.0);',
        'vec3 rgbNW = texture2D(tex, (fragCoord.xy + vec2(-1.0, -1.0)) * inverseVP).xyz;',
        'vec3 rgbNE = texture2D(tex, (fragCoord.xy + vec2(1.0, -1.0)) * inverseVP).xyz;',
        'vec3 rgbSW = texture2D(tex, (fragCoord.xy + vec2(-1.0, 1.0)) * inverseVP).xyz;',
        'vec3 rgbSE = texture2D(tex, (fragCoord.xy + vec2(1.0, 1.0)) * inverseVP).xyz;',
        'vec4 rgbaM  = texture2D(tex, fragCoord.xy  * inverseVP);',
        'vec3 rgbM = rgbaM.xyz;',
        'float opacity = rgbaM.w;',
        'vec3 luma = vec3(0.299, 0.587, 0.114);',
        'float lumaNW = dot(rgbNW, luma);',
        'float lumaNE = dot(rgbNE, luma);',
        'float lumaSW = dot(rgbSW, luma);',
        'float lumaSE = dot(rgbSE, luma);',
        'float lumaM  = dot(rgbM,  luma);',
        'float lumaMin = min(lumaM, min(min(lumaNW, lumaNE), min(lumaSW, lumaSE)));',
        'float lumaMax = max(lumaM, max(max(lumaNW, lumaNE), max(lumaSW, lumaSE)));',

        'vec2 dir;',
        'dir.x = -((lumaNW + lumaNE) - (lumaSW + lumaSE));',
        'dir.y =  ((lumaNW + lumaSW) - (lumaNE + lumaSE));',

        'float dirReduce = max((lumaNW + lumaNE + lumaSW + lumaSE) * (0.25 * FXAA_REDUCE_MUL), FXAA_REDUCE_MIN);',
        'float rcpDirMin = 1.0 / (min(abs(dir.x), abs(dir.y)) + dirReduce);',

        'dir = min(vec2(FXAA_SPAN_MAX, FXAA_SPAN_MAX), max(vec2(-FXAA_SPAN_MAX, -FXAA_SPAN_MAX), dir * rcpDirMin)) * inverseVP;',

        'vec3 rgbA = 0.5 * (texture2D(tex, fragCoord.xy * inverseVP + dir * (1.0 / 3.0 - 0.5)).xyz +',
                           'texture2D(tex, fragCoord.xy * inverseVP + dir * (2.0 / 3.0 - 0.5)).xyz);',

        'vec3 rgbB = rgbA * 0.5 + 0.25 * (texture2D(tex, fragCoord * inverseVP + dir * -0.5).xyz +',
                                         'texture2D(tex, fragCoord.xy * inverseVP + dir * 0.5).xyz);',

        'float lumaB = dot(rgbB, luma);',
        'if ((lumaB < lumaMin) || (lumaB > lumaMax))',
            'return vec4(rgbA, 1.0);',
        'else',
            'return vec4(rgbB, 1.0);',
    '}',

    'uniform sampler2D fbo_texture;',
    'varying vec2 f_texcoord;',
    'void main(void) {',
        'gl_FragColor = applyFXAA(f_texcoord, fbo_texture, vec2(500.0, 500.0));',
    '}'
]);
*/
[
    '#define fxaaTexture2D(t, p, o, r) texture2D(t, p + (o * r), 0.0)',
    '#define fxaaSat(x) clamp(x, 0.0, 1.0)',

    '#define FXAA_QUALITY_PS 8',
    '#define FXAA_QUALITY_P0 1.0',
    '#define FXAA_QUALITY_P1 1.5',
    '#define FXAA_QUALITY_P2 2.0',
    '#define FXAA_QUALITY_P3 2.0',
    '#define FXAA_QUALITY_P4 2.0',
    '#define FXAA_QUALITY_P5 2.0',
    '#define FXAA_QUALITY_P6 4.0',
    '#define FXAA_QUALITY_P7 12.0',

    'vec4 fxaa(vec2 pos, sampler2D tex, vec2 resolution,',
          'float subpixQuality, float edgeThreshold, float edgeThresholdMin) {',
        'vec2 posM;',
        'posM.x = pos.x;',
        'posM.y = pos.y;',

        'vec4 rgbyM = texture2D(tex, posM);',
        'vec3 luma = vec3(0.299, 0.587, 0.114);',
        'float lumaM = dot(rgbyM.xyz, luma);',

        'float lumaS = dot(fxaaTexture2D(tex, posM, vec2(0, 1), resolution.xy).xyz, luma);',
        'float lumaE = dot(fxaaTexture2D(tex, posM, vec2(1, 0), resolution.xy).xyz, luma);',
        'float lumaN = dot(fxaaTexture2D(tex, posM, vec2(0, -1), resolution.xy).xyz, luma);',
        'float lumaW = dot(fxaaTexture2D(tex, posM, vec2(-1, 0), resolution.xy).xyz, luma);',

        'float maxSM = max(lumaS, lumaM);',
        'float minSM = min(lumaS, lumaM);',
        'float maxESM = max(lumaE, maxSM);',
        'float minESM = min(lumaE, minSM);',
        'float maxWN = max(lumaN, lumaW);',
        'float minWN = min(lumaN, lumaW);',
        'float rangeMax = max(maxWN, maxESM);',
        'float rangeMin = min(minWN, minESM);',
        'float rangeMaxScaled = rangeMax * edgeThreshold;',
        'float range = rangeMax - rangeMin;',
        'float rangeMaxClamped = max(edgeThresholdMin, rangeMaxScaled);',
        'bool earlyExit = range < rangeMaxClamped;',
        
        '// maybe return rgbyM -> leave unchanged',
        'if(earlyExit) return rgbyM;',

        'float lumaNW = dot(fxaaTexture2D(tex, posM, vec2(-1, -1), resolution.xy).xyz, luma);',
        'float lumaSE = dot(fxaaTexture2D(tex, posM, vec2(1, 1), resolution.xy).xyz, luma);',
        'float lumaNE = dot(fxaaTexture2D(tex, posM, vec2(1, -1), resolution.xy).xyz, luma);',
        'float lumaSW = dot(fxaaTexture2D(tex, posM, vec2(-1, 1), resolution.xy).xyz, luma);',

        'float lumaNS = lumaN + lumaS;',
        'float lumaWE = lumaW + lumaE;',
        'float subpixRcpRange = 1.0 / range;',
        'float subpixNSWE = lumaNS + lumaWE;',
        'float edgeHorz1 = (-2.0 * lumaM) + lumaNS;',
        'float edgeVert1 = (-2.0 * lumaM) + lumaWE;',

        'float lumaNESE = lumaNE + lumaSE;',
        'float lumaNWNE = lumaNW + lumaNE;',
        'float edgeHorz2 = (-2.0 * lumaE) + lumaNESE;',
        'float edgeVert2 = (-2.0 * lumaN) + lumaNWNE;',

        'float lumaNWSW = lumaNW + lumaSW;',
        'float lumaSWSE = lumaSW + lumaSE;',
        'float edgeHorz4 = (abs(edgeHorz1) * 2.0) + abs(edgeHorz2);',
        'float edgeVert4 = (abs(edgeVert1) * 2.0) + abs(edgeVert2);',
        'float edgeHorz3 = (-2.0 * lumaW) + lumaNWSW;',
        'float edgeVert3 = (-2.0 * lumaS) + lumaSWSE;',
        'float edgeHorz = abs(edgeHorz3) + edgeHorz4;',
        'float edgeVert = abs(edgeVert3) + edgeVert4;',

        'float subpixNWSWNESE = lumaNWSW + lumaNESE;',
        'float lengthSign = resolution.x;',
        'bool horzSpan = edgeHorz >= edgeVert;',
        'float subpixA = subpixNSWE * 2.0 + subpixNWSWNESE;',

        'if(!horzSpan) lumaN = lumaW;',
        'if(!horzSpan) lumaS = lumaE;',
        'if(horzSpan) lengthSign = resolution.y;',
        'float subpixB = (subpixA * (1.0/12.0)) - lumaM;',

        'float gradientN = lumaN - lumaM;',
        'float gradientS = lumaS - lumaM;',
        'float lumaNN = lumaN + lumaM;',
        'float lumaSS = lumaS + lumaM;',
        'bool pairN = abs(gradientN) >= abs(gradientS);',
        'float gradient = max(abs(gradientN), abs(gradientS));',
        'if(pairN) lengthSign = -lengthSign;',
        'float subpixC = fxaaSat(abs(subpixB) * subpixRcpRange);',

        'vec2 posB;',
        'posB.x = posM.x;',
        'posB.y = posM.y;',
        'vec2 offNP;',
        'offNP.x = (!horzSpan) ? 0.0 : resolution.x;',
        'offNP.y = ( horzSpan) ? 0.0 : resolution.y;',
        'if(!horzSpan) posB.x += lengthSign * 0.5;',
        'if( horzSpan) posB.y += lengthSign * 0.5;',

        'vec2 posN;',
        'posN.x = posB.x - offNP.x * FXAA_QUALITY_P0;',
        'posN.y = posB.y - offNP.y * FXAA_QUALITY_P0;',
        'vec2 posP;',
        'posP.x = posB.x + offNP.x * FXAA_QUALITY_P0;',
        'posP.y = posB.y + offNP.y * FXAA_QUALITY_P0;',
        'float subpixD = ((-2.0)*subpixC) + 3.0;',
        'float lumaEndN = texture2D(tex, posN).w;',
        'float subpixE = subpixC * subpixC;',
        'float lumaEndP = texture2D(tex, posP).w;',

        'if(!pairN) lumaNN = lumaSS;',
        'float gradientScaled = gradient * 1.0/4.0;',
        'float lumaMM = lumaM - lumaNN * 0.5;',
        'float subpixF = subpixD * subpixE;',
        'bool lumaMLTZero = lumaMM < 0.0;',

        'lumaEndN -= lumaNN * 0.5;',
        'lumaEndP -= lumaNN * 0.5;',
        'bool doneN = abs(lumaEndN) >= gradientScaled;',
        'bool doneP = abs(lumaEndP) >= gradientScaled;',
        'if(!doneN) posN.x -= offNP.x * FXAA_QUALITY_P1;',
        'if(!doneN) posN.y -= offNP.y * FXAA_QUALITY_P1;',
        'bool doneNP = (!doneN) || (!doneP);',
        'if(!doneP) posP.x += offNP.x * FXAA_QUALITY_P1;',
        'if(!doneP) posP.y += offNP.y * FXAA_QUALITY_P1;',

        'if(doneNP) {',
            'if(!doneN) lumaEndN = dot(texture2D(tex, posN.xy).xyz, luma);',
            'if(!doneP) lumaEndP = dot(texture2D(tex, posP.xy).xyz, luma);',
            'if(!doneN) lumaEndN = lumaEndN - lumaNN * 0.5;',
            'if(!doneP) lumaEndP = lumaEndP - lumaNN * 0.5;',
            'doneN = abs(lumaEndN) >= gradientScaled;',
            'doneP = abs(lumaEndP) >= gradientScaled;',
            'if(!doneN) posN.x -= offNP.x * FXAA_QUALITY_P2;',
            'if(!doneN) posN.y -= offNP.y * FXAA_QUALITY_P2;',
            'doneNP = (!doneN) || (!doneP);',
            'if(!doneP) posP.x += offNP.x * FXAA_QUALITY_P2;',
            'if(!doneP) posP.y += offNP.y * FXAA_QUALITY_P2;',

            '#if (FXAA_QUALITY_PS > 3)',
            'if(doneNP) {',
                'if(!doneN) lumaEndN = dot(texture2D(tex, posN.xy).xyz, luma);',
                'if(!doneP) lumaEndP = dot(texture2D(tex, posP.xy).xyz, luma);',
                'if(!doneN) lumaEndN = lumaEndN - lumaNN * 0.5;',
                'if(!doneP) lumaEndP = lumaEndP - lumaNN * 0.5;',
                'doneN = abs(lumaEndN) >= gradientScaled;',
                'doneP = abs(lumaEndP) >= gradientScaled;',
                'if(!doneN) posN.x -= offNP.x * FXAA_QUALITY_P3;',
                'if(!doneN) posN.y -= offNP.y * FXAA_QUALITY_P3;',
                'doneNP = (!doneN) || (!doneP);',
                'if(!doneP) posP.x += offNP.x * FXAA_QUALITY_P3;',
                'if(!doneP) posP.y += offNP.y * FXAA_QUALITY_P3;',

                '#if (FXAA_QUALITY_PS > 4)',
                'if(doneNP) {',
                    'if(!doneN) lumaEndN = dot(texture2D(tex, posN.xy).xyz, luma);',
                    'if(!doneP) lumaEndP = dot(texture2D(tex, posP.xy).xyz, luma);',
                    'if(!doneN) lumaEndN = lumaEndN - lumaNN * 0.5;',
                    'if(!doneP) lumaEndP = lumaEndP - lumaNN * 0.5;',
                    'doneN = abs(lumaEndN) >= gradientScaled;',
                    'doneP = abs(lumaEndP) >= gradientScaled;',
                    'if(!doneN) posN.x -= offNP.x * FXAA_QUALITY_P4;',
                    'if(!doneN) posN.y -= offNP.y * FXAA_QUALITY_P4;',
                    'doneNP = (!doneN) || (!doneP);',
                    'if(!doneP) posP.x += offNP.x * FXAA_QUALITY_P4;',
                    'if(!doneP) posP.y += offNP.y * FXAA_QUALITY_P4;',

                    '#if (FXAA_QUALITY_PS > 5)',
                    'if(doneNP) {',
                        'if(!doneN) lumaEndN = dot(texture2D(tex, posN.xy).xyz, luma);',
                        'if(!doneP) lumaEndP = dot(texture2D(tex, posP.xy).xyz, luma);',
                        'if(!doneN) lumaEndN = lumaEndN - lumaNN * 0.5;',
                        'if(!doneP) lumaEndP = lumaEndP - lumaNN * 0.5;',
                        'doneN = abs(lumaEndN) >= gradientScaled;',
                        'doneP = abs(lumaEndP) >= gradientScaled;',
                        'if(!doneN) posN.x -= offNP.x * FXAA_QUALITY_P5;',
                        'if(!doneN) posN.y -= offNP.y * FXAA_QUALITY_P5;',
                        'doneNP = (!doneN) || (!doneP);',
                        'if(!doneP) posP.x += offNP.x * FXAA_QUALITY_P5;',
                        'if(!doneP) posP.y += offNP.y * FXAA_QUALITY_P5;',

                        '#if (FXAA_QUALITY_PS > 6)',
                        'if(doneNP) {',
                            'if(!doneN) lumaEndN = dot(texture2D(tex, posN.xy).xyz, luma);',
                            'if(!doneP) lumaEndP = dot(texture2D(tex, posP.xy).xyz, luma);',
                            'if(!doneN) lumaEndN = lumaEndN - lumaNN * 0.5;',
                            'if(!doneP) lumaEndP = lumaEndP - lumaNN * 0.5;',
                            'doneN = abs(lumaEndN) >= gradientScaled;',
                            'doneP = abs(lumaEndP) >= gradientScaled;',
                            'if(!doneN) posN.x -= offNP.x * FXAA_QUALITY_P6;',
                            'if(!doneN) posN.y -= offNP.y * FXAA_QUALITY_P6;',
                            'doneNP = (!doneN) || (!doneP);',
                            'if(!doneP) posP.x += offNP.x * FXAA_QUALITY_P6;',
                            'if(!doneP) posP.y += offNP.y * FXAA_QUALITY_P6;',

                            '#if (FXAA_QUALITY_PS > 7)',
                            'if(doneNP) {',
                                'if(!doneN) lumaEndN = dot(texture2D(tex, posN.xy).xyz, luma);',
                                'if(!doneP) lumaEndP = dot(texture2D(tex, posP.xy).xyz, luma);',
                                'if(!doneN) lumaEndN = lumaEndN - lumaNN * 0.5;',
                                'if(!doneP) lumaEndP = lumaEndP - lumaNN * 0.5;',
                                'doneN = abs(lumaEndN) >= gradientScaled;',
                                'doneP = abs(lumaEndP) >= gradientScaled;',
                                'if(!doneN) posN.x -= offNP.x * FXAA_QUALITY_P7;',
                                'if(!doneN) posN.y -= offNP.y * FXAA_QUALITY_P7;',
                                'doneNP = (!doneN) || (!doneP);',
                                'if(!doneP) posP.x += offNP.x * FXAA_QUALITY_P7;',
                                'if(!doneP) posP.y += offNP.y * FXAA_QUALITY_P7;',

        '#if (FXAA_QUALITY_PS > 8)',
        'if(doneNP) {',
            'if(!doneN) lumaEndN = dot(texture2D(tex, posN.xy).xyz, luma);',
            'if(!doneP) lumaEndP = dot(texture2D(tex, posP.xy).xyz, luma);',
            'if(!doneN) lumaEndN = lumaEndN - lumaNN * 0.5;',
            'if(!doneP) lumaEndP = lumaEndP - lumaNN * 0.5;',
            'doneN = abs(lumaEndN) >= gradientScaled;',
            'doneP = abs(lumaEndP) >= gradientScaled;',
            'if(!doneN) posN.x -= offNP.x * FXAA_QUALITY_P8;',
            'if(!doneN) posN.y -= offNP.y * FXAA_QUALITY_P8;',
            'doneNP = (!doneN) || (!doneP);',
            'if(!doneP) posP.x += offNP.x * FXAA_QUALITY_P8;',
            'if(!doneP) posP.y += offNP.y * FXAA_QUALITY_P8;',

            '#if (FXAA_QUALITY_PS > 9)',
            'if(doneNP) {',
                'if(!doneN) lumaEndN = dot(texture2D(tex, posN.xy).xyz, luma);',
                'if(!doneP) lumaEndP = dot(texture2D(tex, posP.xy).xyz, luma);',
                'if(!doneN) lumaEndN = lumaEndN - lumaNN * 0.5;',
                'if(!doneP) lumaEndP = lumaEndP - lumaNN * 0.5;',
                'doneN = abs(lumaEndN) >= gradientScaled;',
                'doneP = abs(lumaEndP) >= gradientScaled;',
                'if(!doneN) posN.x -= offNP.x * FXAA_QUALITY_P9;',
                'if(!doneN) posN.y -= offNP.y * FXAA_QUALITY_P9;',
                'doneNP = (!doneN) || (!doneP);',
                'if(!doneP) posP.x += offNP.x * FXAA_QUALITY_P9;',
                'if(!doneP) posP.y += offNP.y * FXAA_QUALITY_P9;',

                '#if (FXAA_QUALITY_PS > 10)',
                'if(doneNP) {',
                    'if(!doneN) lumaEndN = dot(texture2D(tex, posN.xy).xyz, luma);',
                    'if(!doneP) lumaEndP = dot(texture2D(tex, posP.xy).xyz, luma);',
                    'if(!doneN) lumaEndN = lumaEndN - lumaNN * 0.5;',
                    'if(!doneP) lumaEndP = lumaEndP - lumaNN * 0.5;',
                    'doneN = abs(lumaEndN) >= gradientScaled;',
                    'doneP = abs(lumaEndP) >= gradientScaled;',
                    'if(!doneN) posN.x -= offNP.x * FXAA_QUALITY_P10;',
                    'if(!doneN) posN.y -= offNP.y * FXAA_QUALITY_P10;',
                    'doneNP = (!doneN) || (!doneP);',
                    'if(!doneP) posP.x += offNP.x * FXAA_QUALITY_P10;',
                    'if(!doneP) posP.y += offNP.y * FXAA_QUALITY_P10;',

                    '#if (FXAA_QUALITY_PS > 11)',
                    'if(doneNP) {',
                        'if(!doneN) lumaEndN = dot(texture2D(tex, posN.xy).xyz, luma);',
                        'if(!doneP) lumaEndP = dot(texture2D(tex, posP.xy).xyz, luma);',
                        'if(!doneN) lumaEndN = lumaEndN - lumaNN * 0.5;',
                        'if(!doneP) lumaEndP = lumaEndP - lumaNN * 0.5;',
                        'doneN = abs(lumaEndN) >= gradientScaled;',
                        'doneP = abs(lumaEndP) >= gradientScaled;',
                        'if(!doneN) posN.x -= offNP.x * FXAA_QUALITY_P11;',
                        'if(!doneN) posN.y -= offNP.y * FXAA_QUALITY_P11;',
                        'doneNP = (!doneN) || (!doneP);',
                        'if(!doneP) posP.x += offNP.x * FXAA_QUALITY_P11;',
                        'if(!doneP) posP.y += offNP.y * FXAA_QUALITY_P11;',

                        '#if (FXAA_QUALITY_PS > 12)',
                        'if(doneNP) {',
                            'if(!doneN) lumaEndN = dot(texture2D(tex, posN.xy).xyz, luma);',
                            'if(!doneP) lumaEndP = dot(texture2D(tex, posP.xy).xyz, luma);',
                            'if(!doneN) lumaEndN = lumaEndN - lumaNN * 0.5;',
                            'if(!doneP) lumaEndP = lumaEndP - lumaNN * 0.5;',
                            'doneN = abs(lumaEndN) >= gradientScaled;',
                            'doneP = abs(lumaEndP) >= gradientScaled;',
                            'if(!doneN) posN.x -= offNP.x * FXAA_QUALITY_P12;',
                            'if(!doneN) posN.y -= offNP.y * FXAA_QUALITY_P12;',
                            'doneNP = (!doneN) || (!doneP);',
                            'if(!doneP) posP.x += offNP.x * FXAA_QUALITY_P12;',
                            'if(!doneP) posP.y += offNP.y * FXAA_QUALITY_P12;',
                        '}',
                        '#endif',
                    '}',
                    '#endif',
                '}',
                '#endif',
            '}',
            '#endif',
        '}',
        '#endif',
                            '}',
                            '#endif',
                        '}',
                        '#endif',
                    '}',
                    '#endif',
                '}',
                '#endif',

            '}',
            '#endif',
        '}',

        'float dstN = posM.x - posN.x;',
        'float dstP = posP.x - posM.x;',
        'if(!horzSpan) dstN = posM.y - posN.y;',
        'if(!horzSpan) dstP = posP.y - posM.y;',

        'bool goodSpanN = (lumaEndN < 0.0) != lumaMLTZero;',
        'float spanLength = (dstP + dstN);',
        'bool goodSpanP = (lumaEndP < 0.0) != lumaMLTZero;',
        'float spanLengthRcp = 1.0 / spanLength;',

        'bool directionN = dstN < dstP;',
        'float dst = min(dstN, dstP);',
        'bool goodSpan = directionN ? goodSpanN : goodSpanP;',
        'float subpixG = subpixF * subpixF;',
        'float pixelOffset = (dst * (-spanLengthRcp)) + 0.5;',
        'float subpixH = subpixG * subpixQuality;',

        'float pixelOffsetGood = goodSpan ? pixelOffset : 0.0;',
        'float pixelOffsetSubpix = max(pixelOffsetGood, subpixH);',
        'if(!horzSpan) posM.x += pixelOffsetSubpix * lengthSign;',
        'if( horzSpan) posM.y += pixelOffsetSubpix * lengthSign;',

        '// maybe return vec4(texture2D(tex, posM).xyz, lumaM);',
        'return texture2D(tex, posM);',
    '}',

    'uniform sampler2D fbo_texture;',
    'uniform vec2 resolution;',
    'varying vec2 f_texcoord;',
    'void main(void) {',
        'gl_FragColor = fxaa(f_texcoord, fbo_texture, vec2(1.0 / resolution.x, 1.0 / resolution.y), 0.75, 0.166, 0.0833);',
    '}'
]);